<template>
  <div class="product-detail">
    <div class="product-name">
      <div class="content">
        <div>{{detail.name}}</div>
        <div>
          <a href="#detail">产品介绍</a>
          <a href="#product">相关产品</a>
          <a href="/contactUs">联系我们</a>
        </div>

      </div>
    </div>
    <div class="content" id="detail">
      <img v-for="(item, index) in detail.detail" :key="index" :alt="item.name + '详情图'" :src="item" :title="detail.name + '详情图'" />
    </div>
    <div class="content" id="product">相关产品</div>
    <div class="content product-list">
      <a :href="'/product/detail/?id='+item.id" :title="item.name" class="product-item" v-for="(item, index) in productList" :key="index">
        <img :src="item.img" :alt="item.name">
        <div>{{item.name}}</div>
        <div>{{item.code}}</div>
        <div>查看详情</div>
      </a>
    </div>
  </div>
</template>

<script>
import product from "@/assets/hypocritical/product.js"
const productList = []
Object.keys(product).map(key => productList.push(...product[key].list))
export default {
  metaInfo: {
    title: "深圳多知能源物联有限公司专注智能家居,烟雾报警器,燃气报警器",
    meta: [
      {
        name: "keywords",
        content: [...product.intelligentHardware.list, ...product.softwarePlatform.list].map(it => it.name),
      },
      {
        name: "description",
        content: "优气物联致力于“液化石油气LPG” 物联网应用软硬件产品的研发、生产、销售和服务。旨在为燃气企业赋能，降低成本，提升效率，为监管单位提供高效精准的监管渠道，为终端用户带来高质量的用气体验。打造一个聚焦LPG领域，融合新零售、采用大数据、物联网等前沿技术的一站式服务平台。",
      },
    ],
  },
  data(){
    return {
      detail: {},
      productList: []
    }
  },
  created(){
    this.detail = productList.find(it => it.id == this.$route.query.id)
    this.productList = productList.filter(it => it.id != this.$route.query.id).splice(0, 4)
    console.log(this.productList,"detail")
  }
}
</script>

<style lang="scss" scoped>
.content{
  width: 1200px;
  & > img{
    max-width: 100%;
  }
  
}
.product-name{
  background: #f0f0f0;
  .content{
    height: 72px;
    & > div:nth-child(1){
      float: left;
      font-size: 24px;
      color: #444;
      line-height: 72px;
    }
    & > div:nth-child(2){
      display: flex;
      float: right;
      height: 72px;
      align-items: center;
      & > a{
        margin-left: 40px;
        color: #535353;
      }
      & > a:nth-child(3){
        background: #2da0db;
        box-sizing: border-box;
        width: 100px;
        line-height: 35px;
        line-height: 1;
        padding: 8px 15px;
        color: #fff;
      }
    }

  }
}
#detail{
  padding-top: 60px;
}
#product{
  padding-top: 60px;
  font-weight: 600;
  text-align: left
}
.product-list{
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
  .product-item{
    width: 23%;
    margin: 10px 1%;
    background: #f9f9f9;
    cursor: pointer;
    overflow: hidden;
    img{
      width: 100%;
      margin: 20% 0;
      transition: all .2s;
    }
    & > div:nth-child(2){
      color: #535353;
      line-height: 30px;
    }
    & > div:nth-child(3){
      color: #535353;
      font-size: 12px;
      line-height: 30px;
    }
    & > div:nth-child(4){
      width: fit-content;
      border: 1px solid #e3e3e3;
      padding: 2px 10px;
      border-radius: 6px;
      margin: 0 auto;
      margin-bottom: 20px;
      transition: all .2s;
    }
  }
  .product-item:hover{
    img{
      transform: scale(1.1);
    }
    & > div:nth-child(4){
      color: white;
      background: #2da0db;
    }
  }
}
@media screen and (max-width: 600px) {
  .content{
    width: 98%!important;
  }
  #detail{
    img{
      width: 100%;
    }
  }
}

</style>